:root {
    --primary-dark: red;
    --primary-main: white;
    --primary-gray: rgb(244, 244, 244);
    --primary-body: white;
    --primary-text: black;
    --white-text: white;
    --gray: rgba(128, 128, 128, 0.185);
    --dark-gray: gray;
    --decription-color: rgb(196, 194, 190);
    --primary-blue: rgb(14, 21, 50);
    --primary-category-bg:rgb(26,140,178);
}