@import 'variables.css';

/* Search */
#search-container {
    padding: 10px 20px;
    justify-content: end;
    align-items: center;
}

#nav-search-container {
    margin: '8px';
    background-color: #fff;
    padding: 10px;
    border-radius: 16px;
    max-width: 250px;
}

.logo-text {
    margin: 0px;
    font-size: 20px;
    text-transform: uppercase;
    background: linear-gradient(80deg, rgb(255, 0, 0), rgb(0, 127, 247));
    font-weight: 900;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -webkit-font-smoothing: antialiased;
}


/* Nav-Tabs */
.nav-tabs-name {
    cursor: pointer;
    display: inline-block;
    position: relative;
    color: var(--primary-dark) !important;
    font-size: 16px;
}
@media (max-width: 1100px) {
    .nav-tabs-name {
        font-size: small;
    }
}
@media (max-width: 1000px) {
    .nav-tabs-name {
        font-size:12px;
    }
}
.underline-animation:after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 4px;
    bottom: 0;
    left: 0;
    background-color: var(--primary-dark);
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
}

.underline-animation:hover:after {
    transform: scaleX(1);
    transform-origin: bottom left;
}

.menu-container {
    margin: '8px';
    position: absolute;
    background-color: #fff;
    padding: 10px;
    z-index: 999;
    height: fit-content;
    transform: translate(-50%, 25px);

}

@keyframes opacity-up {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes opacity-down {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}