.MyStoryView {
  -webkit-animation: none;
  -moz-animation: none;
  -ms-animation: none;
  animation: none;
  width: 100vw;
  height: 100vh;
}

.MyStoryView .description p{
    font-weight: bold;
    font-family: 'Roboto', sans-serif;
}

.MyStoryView amp-story {
  font-family: 'Oswald',sans-serif;
  color: #fff;
}
.MyStoryView amp-story-page {
  background-color: #000;
}
.MyStoryView h1 {
  font-weight: bold;
  font-size: 2.875em;
  font-weight: normal;
  line-height: 1.174;
}
.MyStoryView p {
  font-weight: normal;
  font-size: 1.3em;
  line-height: 1.5em;
  color: #fff;
}
.MyStoryView q {
  font-weight: 300;
  font-size: 1.1em;
}
.MyStoryView amp-story-grid-layer.bottom {
  align-content:end;
}
.MyStoryView amp-story-grid-layer.noedge {
  padding: 0px;
}
.MyStoryView amp-story-grid-layer.center-text {
  align-content: center;
}
.MyStoryView .wrapper {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: 50% 50%;
}
.MyStoryView .banner-text {
  text-align: center;
  background-color: #000;
  line-height: 2em;
}

 .MyStoryView {
  -webkit-animation: -amp-start 8s steps(1, end) 0s 1 normal both;
  -moz-animation: -amp-start 8s steps(1, end) 0s 1 normal both;
  -ms-animation: -amp-start 8s steps(1, end) 0s 1 normal both;
  animation: -amp-start 8s steps(1, end) 0s 1 normal both
}

@-webkit-keyframes -amp-start {
  from {
    visibility: hidden
  }

  to {
    visibility: visible
  }
}

@-moz-keyframes -amp-start {
  from {
    visibility: hidden
  }

  to {
    visibility: visible
  }
}

@-ms-keyframes -amp-start {
  from {
    visibility: hidden
  }

  to {
    visibility: visible
  }
}

@-o-keyframes -amp-start {
  from {
    visibility: hidden
  }

  to {
    visibility: visible
  }
}

@keyframes -amp-start {
  from {
    visibility: hidden
  }

  to {
    visibility: visible
  }
}