@import 'variables.css';

.user-app {
  font-family: 'Roboto', sans-serif;
  background-color: var(--primary-main);
}

.my-color-picker {
  max-width: 100% !important;
}

.images_drag label {
  min-width: 100% !important;
}

.images_drag input {
  width: 100% !important;
}

.container {
  max-width: 1300px !important;
  /* padding: 16px; */
}

.post-heading {
  color: var(--primary-text);
  font-size: 2em;
}

.my-footer {
  color: var(--primary-text) !important;
  font-size: 20px;
  width: 100% !important;
  background-color: var(--primary-light);
  min-height: 150px !important;
  justify-content: end !important;
  align-items: center !important;
  font-size: 16px;
}

.footer-icons {
  color: white;
  background-color: var(--primary-dark);
  padding: 4px;
  font-size: 36px;
  border-radius: 8px;
  margin-bottom: 8px;
  margin-top: 8px;
  cursor: pointer;
  margin-right: 16px;
  transition: transform 0.5s ease-in-out;
}

.footer-icons:hover {
  transform: scale(1.1);
}

.my-footer p {
  cursor: pointer;
  display: inline-block;
  position: relative;
}

.showImages {
  position: relative;
  width: 200px;
  height: 200px;
  display: inline-block;
  max-width: 400px;
}

.removeImageBtn {
  margin: 0px;
  height: 25px;
  width: 25px;
  border-color: white;
  border-radius: 100%;
  color: white;
  position: absolute;
  top: 1%;
  left: 95%;
  transform: translate(-100%, 15%);
  cursor: pointer;
  text-align: center;
  background-color: transparent;
  font-size: small;
  box-shadow: none;
}

.cursor-pointer {
  cursor: pointer !important;
}

/* For Loader */
.spinner {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.spinner-container {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.spinner-loader {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 5px solid rgb(255, 255, 255);
  border-top: 5px solid transparent;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.imageModal {
  box-shadow: none !important;
  border: none !important;
  background-color: transparent !important;
}


.profile-container {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  position: relative;
}

.profile-img {
  height: 100%;
  width: 100%;
  border-radius: 50%;
}

.profile-icon {
  position: absolute;
  bottom: 10px;
  right: -7px;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  display: flex !important;
  align-items: center;
  justify-content: center;
  background-color: white;
  color: cornflowerblue;
  box-shadow: 0 0 8px 3px;
}

.dropdown-toggle::after {
  display: none !important;
}

.home-main-content {
  width: 100%;
  height: 100%;
  padding: 4px;
  position: relative;
  cursor: pointer;
  overflow: hidden;
}

.home-main-content img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.description-text {
  color: var(--decription-color);
}

.name-with-line {
  width: 100%;
  font-size: 24px;
  border-bottom: 2px solid #000;
  padding-top: 50px;
  margin-bottom: 50px;
}

.name-with-line span {
  background: transparent;
  padding: 0 10px;
}

.first-letter-capital:first-letter {
  text-transform: uppercase;
}

.hover-with-effect {
  transition: all .5s ease-in-out;
}

.hover-with-effect:hover {
  color: var(--primary-dark);
  cursor: pointer;
  transform: scale(1);
}

@keyframes downToUp {
  0% {
    transform: translateY(10%);
    opacity: 0;
  }

  100% {
    transform: translateY(0%);
    opacity: 1;
  }
}

.hot-news:hover {
  text-decoration: underline;
  color: var(--primary-dark);
}

.quick-links:hover {
  text-decoration: underline;
  color: var(--primary-dark);
}

.blog-titel:hover {
  text-decoration: underline var(--primary-dark) 2px !important;
}

.navigate-button {
  background-color: var(--gray) !important;
  margin-left: 8px !important;
  margin-right: 8px !important;
  font-size: 12px ip !important;
  width: 120px !important;
  text-align: center !important;
  border: none;
  border-radius: 8px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.navigate-button:hover:enabled {
  background-color: var(--primary-dark) !important;
  color: white !important;
  box-shadow: 5px 5px 5px gray;
}

.mini-loader {
  font-size: xx-large;
  color: var(--primary-dark);
}

.image-shadow img {
  filter: drop-shadow(5px 5px 5px rgba(0, 0, 0, 0.5));
}

.post-option {
  z-index: 999;
}

.post-option:hover {
  background-color: rgba(69, 128, 218, 0.76) !important;
  color: white;
  border-radius: 16px;
}

/* Class name for the chosen item */
.sortable-chosen {
  box-shadow: 8px 8px 32px #e1e1e1;
}

/* Class name for the dragging item */
.sortable-drag {
  opacity: 0;
}